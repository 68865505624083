import React from 'react';
import Logo from "../img/Logo1.png";
import html2canvas from "html2canvas";

export const Invoices = () => {

    const [itemsData, setItemsData] = React.useState([{
        name: "",
        price: 0,
        total: 0,
        description: "",
        quantity: 1
    }]);

    const handleChange = (index, event) => {
        const list = [...itemsData];
        const { name, value } = event.target;
    
        list[index][name] = value;
    
        if (name === "quantity" || name === "price") {
            list[index].total = list[index].quantity * list[index].price;
        }
    
        setItemsData(list);
    };
    

    const addMoreItems = () => {
        setItemsData([...itemsData, {
            name: "",
            price: 0,
            quantity: 1,
            total: 0,
            description: "",
        }]);
    };

    const CalculateTotal = () => {
        let total = 0;
        itemsData.forEach((item) => {
            total = total + parseFloat(item.total);
        });
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(total);    
    };

    const downloadInvoices = () => {
        const input = document.getElementById("invoice");
        html2canvas(input, { 
            useCORS: true,
            onclone: function (clonedDoc) {
                const inputs = clonedDoc.querySelectorAll("input, textarea");
                inputs.forEach((input) => {
                const span = clonedDoc.createElement("span");
                span.textContent = input.value;
                span.style.cssText = window.getComputedStyle(input).cssText;
                input.replaceWith(span);
            });
        }
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = imgData;
            link.download = "invoice.png";
            link.click();
            setItemsData([{}]);
        });
    };

    const removeItem = (index) => {
        const list = [...itemsData];
        list.splice(index, 1);
        setItemsData(list);
    };

    return (
        <section className=' w-screen h-screen font-Lora p-12 bg-white'>
            <div id='invoice' className=' mx-auto border shadow-2xl h-[800px] max-w-screen-lg'>
                <div className=' flex flex-col'>
                    <div className=' bg-invoice-blue text-white flex px-8 py-4 justify-between items-center'>
                        <div className=' flex items-center space-x-4'>
                            <img className=' w-40' src={Logo} alt=" logo" />
                            <div className=' flex font-bold space-y-1 flex-col'>
                                <h2>
                                    3811 McKee Road, San Jose, CA 95127
                                </h2>
                                <span>
                                    Phone: (669) 699-6999
                                </span>
                                <span>
                                    Email: contact@bdhomegroup.us
                                </span>
                            </div>
                        </div>
                        <div className=''>
                            <h1 className=' text-4xl tracking-wide text-white font-bold'>Invoices</h1>
                        </div>
                    </div>
                    <div className='px-8 py-4 flex justify-between'>
                        <div className=' flex flex-col'>
                            <h3 className=' font-bold text-md tracking-wider text-black'>
                                Bill To:
                            </h3>
                            <input className=' py-2 outline-none border-b text-sm' type="text" placeholder='Customer Name' />
                            <textarea className=' py-2 resize-none outline-none border-b text-sm' type="text" placeholder='Customer Address' />
                            <input className=' py-2 outline-none border-b text-sm' type="text" placeholder='Customer Phone' />
                        </div>
                        <div className=' flex text-gray-500 flex-col space-y-2'>
                            <div className=' flex space-x-2'>
                                <h4 className='tracking-wide'>
                                    Invoice #: 
                                </h4>
                                <input className='outline-none border-b' type="number" placeholder='Number' />
                            </div>
                            <div className=' flex space-x-2'>
                                <h4 className='tracking-wide'>
                                    Date: 
                                </h4>
                                <input className='outline-none border-b' type="text" placeholder={new Date().toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                })} />
                            </div>
                        </div>
                    </div>
                    <hr className=' mx-4 border-invoice-blue'></hr>
                    <div className=' px-8 py-4'>
                        <table className='table-fixed w-full'>
                            <thead className=' bg-invoice-blue text-white'>
                                <tr>
                                    <th className='p-4 text-left'>Items</th>
                                    <th className='p-4 text-left'>Description</th>
                                    <th className='p-4 text-left'>Quantity</th>
                                    <th className='p-4 text-left'>Price</th>
                                    <th className='p-4 text-left'>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemsData.map((item, index) => (
                                    <tr key={index} className=' text-sm border-b'>
                                        <td className='p-4'>
                                            <input name='name' className=' outline-none border-none' type="text" placeholder='Item Name' />
                                        </td>
                                        <td className='p-4'>
                                            <input name='description' className=' outline-none border-none' type="text" placeholder='Description....' />
                                        </td>
                                        <td className='p-4'>
                                            <input name='quantity' value={item.quantity} onChange={(e) => handleChange(index, e)} className=' outline-none border-none' type="number" placeholder={item.quantity} />
                                        </td>
                                        <td className='p-4'>
                                            <input name='price' value={item.price === 0 ? '$' : item.price} onChange={(e) => handleChange(index, e)} className=' outline-none border-none' type="number" placeholder={ item.price === 0 ? '$' : item.price} />
                                        </td>
                                        <td className='p-4'>
                                            <input name='total' onChange={(e) => handleChange(index, e)} value={item.total === 0 ? '$' : item.total} className=' outline-none border-none' type="number" placeholder={ item.total === 0 ? '$' : item.total} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className=' px-8 py-4'>
                        <div className=' flex flex-col text-lg items-end'>
                            <div className=' flex space-x-2'>
                                <span className=' font-bold'>Total: </span>
                                <span>${CalculateTotal()}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' flex mt-8 justify-center'>
                <button onClick={() => addMoreItems()} className=' w-[180px] rounded-full shadow-2xl py-2 text-white bg-invoice-blue'>
                    Add More Items
                </button>
                <button onClick={() => downloadInvoices()} className=' w-[180px] ml-4 rounded-full shadow-2xl py-2 text-white bg-invoice-blue'>
                    Download Invoice
                </button>
            </div>
        </section>
    )
}
